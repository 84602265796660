import React, { Fragment } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

import { VerticalTimeline } from "react-vertical-timeline-component";
import { currentTimeInMilliSecs } from "../../Helpers/Time";
import MeetingTimelineElement from "./MeetingTimelineElement";
import { v4 as uuidv4 } from "uuid";
import PerfectScrollbar from "react-perfect-scrollbar";
import CallRequestElement from "./CallRequestElement";
import {
  agentHasIncomingRequests,
  incomingRequests,
} from "../../States/Meetings";
import { BsEyeFill } from "react-icons/bs";

class CallRequests extends React.Component {
  constructor(props) {
    super(props);
    this.no_meeting = {
      id: uuidv4(),
      meeting_name: "No incoming requests",
      description: "Go grab a coffee! :-)",
      meeting_status: "CANCELLED",
      meeting_type: "conference",
      scheduled_time: currentTimeInMilliSecs(),
      incoming_requests: incomingRequests.get(),
    };

    this.state = {
      incoming_requests: incomingRequests.get() || [],
      chatOnly: this.props.chatOnly,
    };
    this.timers = [];
    this.componentDidMount = this.componentDidMount.bind(this);
    this.redraw = this.redraw.bind(this);
    this.incomingRequestsListener = this.incomingRequestsListener.bind(this);
    // this.fetch_incoming_requests = this.fetch_incoming_requests.bind(this);
  }

  incomingRequestsListener(requests) {
    this.setState({ incoming_requests: requests });
  }

  // fetch_incoming_requests() {
  //   getCall({
  //     url: config.url.SOURCES,
  //     path: "agent/get_incoming_requests/20",
  //     onSuccess: (response) => {
  //       this.setState({incoming_requests: response.data});
  //     },
  //     onFailure: (error) => {
  //       bottomAlert("Unable to load incoming requests. " + ((error.response && error.response.data.detail) || error.toString()), 'warning');
  //     }
  //   });
  // }

  redraw() {
    this.componentDidMount();
  }

  componentDidMount() {
    incomingRequests.subscribe(this.incomingRequestsListener);
  }

  componentWillUnmount() {
    incomingRequests.unsubscribe(this.incomingRequestsListener);
  }

  render() {
    const params = {};
    if (this.props.notime) {
      params["notime"] = true;
    }
    var incoming_requests = [];
    this.state.incoming_requests.forEach((incoming_request, index) => {
      if (
        (this.props.title === "Incoming requests for you" ||
          this.props.title === "Incoming requests") &&
        incoming_request.message_type === "whatsapp_notification"
      ) {
        // do nothing
      } else if (
        !this.state.chatOnly ||
        incoming_request.message_type === "chat_request" ||
        incoming_request.message_type === "whatsapp_notification"
      ) {
        incoming_requests.push(
          <CallRequestElement
            onSilent={this.props.onSilent}
            onAccept={this.props.onAccept}
            removeRequestByConversationId={
              this.props.removeRequestByConversationId
            }
            incoming_request={incoming_request}
            key={`key-${index}-${incoming_request.queued_on}`}
            call_time={incoming_request.queued_on}
            redraw={this.props.redraw}
            handleOverlay={this.props.handleOverlay}
            {...params}
          />
        );
      }
    });

    const cardTitleStyle = {
      background: "#3ac47d",
      color: "white",
      padding: "10px 15px",
      marginBottom: 0,
    };

    if (incoming_requests.length > 0 && agentHasIncomingRequests.get() > 0) {
      return (
        <Fragment>
          <Card
            className="main-card"
            style={{
              height: "100%",
            }}
          >
            <CardBody style={{ padding: this.props.popup && 0 }}>
              <CardTitle style={this.props.popup && cardTitleStyle}>
                {window.location.pathname === "/workspace/admin" ? (
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "8px", cursor: "pointer" }}
                    onClick={() => {
                      this.props.history.push(
                        "/workspace/incoming-request-queue"
                      );
                    }}
                  >
                    <p className="m-0 p-0">
                      {this.props.title}: {incoming_requests?.length}
                    </p>
                    <BsEyeFill />
                  </div>
                ) : (
                  this.props.title
                )}
                {window.location.pathname.includes("incoming-request-queue")
                  ? incoming_requests?.length
                  : ""}
              </CardTitle>
              <PerfectScrollbar style={{ padding: this.props.popup && 15 }}>
                {this.props.notime ? (
                  <div
                    className="vertical-timeline-element-content bounce-in"
                    style={{ margin: "0px" }}
                  >
                    {incoming_requests}
                  </div>
                ) : (
                  <VerticalTimeline layout="1-column">
                    {" "}
                    {incoming_requests}{" "}
                  </VerticalTimeline>
                )}
              </PerfectScrollbar>
            </CardBody>
          </Card>
        </Fragment>
      );
    } else {
      return (
        !this.props.popup && (
          <Fragment>
            <Card
              className="main-card"
              style={{
                height: "100%",
              }}
            >
              <CardBody>
                <CardTitle>{this.props.title}</CardTitle>
                <PerfectScrollbar>
                  <VerticalTimeline layout="1-column">
                    <MeetingTimelineElement
                      meeting={this.no_meeting}
                      redraw={this.redraw}
                    />
                  </VerticalTimeline>
                </PerfectScrollbar>
              </CardBody>
            </Card>
          </Fragment>
        )
      );
    }
  }
}

export default CallRequests;
