class WebSocketManager {
  constructor(socket) {
    if (!WebSocketManager.instance) {
      this.socket = socket;
      this.listeners = {};
      this.listenersName = {};

      const events = [
        'message', 'chat_message', 'ai_chat_message', 
        'whatsapp_chat_message', 'whatsapp_notification', 
        'ai_notification_message', 'whatsapp_notification_message', 
        'people_dropped'
      ];

      events.forEach(event => {
        this.socket?.on(event, (message) => {
          this.emit(event, message);
        });
      });

      WebSocketManager.instance = this;
    }

    return WebSocketManager.instance;
  }

  on(event, listener, listenerName) {
    let flag = false;

    if (!this.listeners[event]) {
      this.listeners[event] = [];
      this.listenersName[event] = [];
    }
    
    this.listenersName[event].forEach((functionName) => {
      if(functionName === listenerName) {
        flag = true;
      }
    })
  
    if(!flag) {
      this.listenersName[event].push(listenerName);
      this.listeners[event].push(listener);
    }

    // console.log("listeners event ", this.listeners);
    // console.log("listeners name ", this.listenersName);
  }

  emit(event, data) {
    if (this.listeners[event]) {
      this.listeners[event].forEach((listener) => listener(data));
    }
  }

  disconnect() {
    this.socket.disconnect();
  }
}

export default WebSocketManager;