import { bottomAlert } from "../Components/Notifications/ToastAlerts";
import { getCall, deleteCall } from "./Api";
import { meetingAction, meetingContext } from "../States/Meetings";
import { getActiveClient } from "../Helpers/Plan";
import { config } from "../config";
import { getUser } from "./User";
import { msToMinuteSec } from "./Time";

export function endMeeting({
  meeting = null,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  if (!meeting) {
    meeting = meetingContext.get();
  }
  if (!meeting) {
    return onSuccess();
  }
  meetingAction.clearListeners();

  getCall({
    url: config.url.MEETINGS,
    path: "end/" + meeting.id,
    onSuccess: (response) => {
      meetingContext.reset();
      bottomAlert("Your meeting has been closed.", "success");
      onSuccess(response);
    },
    onFailure: (error) => {
      bottomAlert(
        "Unable to close your meeting. " +
          ((error.response && error.response.data.detail) || error.toString()),
        "warning"
      );
      onFailure(error);
      meetingContext.refresh();
    },
  });
}

export function getBackendToken({
  meeting = null,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  if (!meeting) {
    meeting = meetingContext.get();
  }

  getCall({
    url: config.url.MEETINGS,
    path: "backend_token/" + meeting.id,
    onSuccess: (response) => onSuccess(response),
    onFailure: (error) => {
      bottomAlert(
        "Unable to fetch backend token. " +
          ((error.response && error.response.data.detail) || error.toString()),
        "warning"
      );
      onFailure(error);
    },
  });
}

export function startMeeting({
  meeting = null,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  if (!meeting) {
    meeting = meetingContext.get();
  }

  getCall({
    url: config.url.MEETINGS,
    path: "start/" + meeting.id,
    onSuccess: (response) => onSuccess(response),
    onFailure: (error) => {
      bottomAlert(
        "Your meeting could not be started. " +
          (error.response ? error.response.data.detail : error.toString()),
        "warning"
      );
      onFailure(error);
    },
  });
}

export function joinMeetingByUrl({ url = null }) {
  getCall({
    url: config.url.MEETINGS,
    path: "join_by_url/" + window.btoa(url),
    onSuccess: (response) => onSuccess(response),
    onFailure: (error) => {
      bottomAlert(
        "Meeting couldn't be joined. " +
          (error.response ? error.response.data.detail : error.toString()),
        "warning"
      );
      onFailure(error);
    },
  });
}

export function getUserRole({ meeting = null, user_name = "" }) {
  if (!meeting) {
    meeting = meetingContext.get();
  }

  const user = getUser();
  if (user.user_name == user_name && user.id == meeting.created_by_user_id) {
    return "owner";
  } else if (meeting.participants.includes(user_name)) {
    return "participant";
  } else if (meeting.moderators.includes(user_name)) {
    return "moderator";
  } else {
    return "public";
  }
}

export function didAttendMeeting({ meeting = null, user_name = "" }) {
  if (!meeting) {
    meeting = meetingContext.get();
  }
  if (meeting.tokens[user_name]) {
    return true;
  } else {
    return false;
  }
}

export function getTimeInMeeting({ meeting = null, user_name = "" }) {
  if (!meeting) {
    meeting = meetingContext.get();
  }
  if (didAttendMeeting({ meeting: meeting, user_name: user_name })) {
    return msToMinuteSec(meeting.tokens[user_name].time_in_meeting);
  } else {
    return "";
  }
}

export function getMeetingToken({ meeting = null, user_name = "" }) {
  if (!meeting) {
    meeting = meetingContext.get();
  }

  return meeting.tokens[user_name].token;
}

export function getPublicToken({ meeting = null }) {
  var user_name = "public";
  if (!meeting) {
    meeting = meetingContext.get();
  }

  return meeting.tokens[user_name].token;
}

export function getPublicShareUrl({ meeting = null }) {
  if (!meeting) {
    meeting = meetingContext.get();
  }

  if (meeting) {
    const protocol = config.default_meeting_domain.includes("localhost")
      ? "http"
      : "https";
    return config.url.ACTIONS + "/join_meeting/" + meeting.id + "/public";
  }
  return null;
}

export function getRegistrationFormUrl({ meeting = null }) {
  if (!meeting) {
    meeting = meetingContext.get();
  }

  if (meeting) {
    return config.url.BASE + "/source/" + "registration/" + meeting.id;
  }
  return null;
}

export function getMeetingDomain() {
  var client = getActiveClient();
  var dns_domain = config.default_meeting_domain;
  if (client.dns_domain) {
    dns_domain = client.dns_domain;
  }
  return dns_domain;
}

export function getTokenKeyFromString(string) {
  const searchRegExp = /\./g;
  const replaceWith = "(dot)";
  return string.replace(searchRegExp, replaceWith);
}

export function deleteMeeting({
  meeting_id = null,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  deleteCall({
    url: config.url.MEETINGS,
    path: meeting_id,
    onSuccess: (response) => {
      // bottomAlert("Your meeting and related recording was deleted!", "success");
      onSuccess(response);
    },
    onFailure: (error) => {
      bottomAlert(
        "Your meeting could not be deleted. " +
          (error.response ? error.response.data.detail : error.toString()),
        "warning"
      );
      onFailure(error);
    },
  });
}

export function meetingAnalytics({
  meeting_type = "",
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var path = "analytics/last_month";
  if (meeting_type) {
    path = path + "/" + meeting_type;
  }
  getCall({
    url: config.url.MEETINGS,
    path: path,
    onSuccess: (response) => {
      onSuccess(response);
    },
    onFailure: (error) => {
      console.log(error);
      onFailure(error);
    },
  });
}

export function getMeetingIcon(meeting_type) {
  if (meeting_type == "conference") {
    return "pe-7s-ribbon";
  } else if (meeting_type == "webinar") {
    return "pe-7s-speaker";
  } else if (meeting_type == "live_class") {
    return "pe-7s-study";
  } else if (meeting_type == "live_streaming") {
    return "pe-7s-way";
  } else if (meeting_type == "live_call") {
    return "pe-7s-headphones";
  } else if (meeting_type == "talk_to_me") {
    return "pe-7s-headphones";
  } else if (meeting_type == "video_kiosk") {
    return "pe-7s-headphones";
  } else {
    return "";
  }
}
