import React, { Fragment } from "react";

import { Button, DropdownItem } from "reactstrap";
import {
  bottomAlert,
  topAlert,
} from "../../../Components/Notifications/ToastAlerts";
import { config } from "../../../config";
import { getCall, postCall } from "../../../Helpers/Api";
import { noDelaySetInterval, sleep } from "../../../Helpers/Time";
import { socketContext } from "../../../States/Socket";
import {
  activeRequestContext,
  agentHasIncomingRequests,
  agentStatus,
  callRequestContext,
  incomingRequests,
  meetingContext,
  snoozeRing,
  userSession,
} from "../../../States/Meetings";
import { withRouter } from "react-router";
import { hasCookie } from "../../../Helpers/Cookie";
import { notificationsHandle } from "../../../States/Connections";
import { underscoreToSpacedCamel } from "../../../Helpers/Text";
import cx from "classnames";
import { getUser } from "../../../Helpers/User";
import { Card, Tooltip } from "@material-ui/core";
import CallRequests from "../../../Components/Timeline/CallRequests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBellSlash,
  faClose,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faSnooze, faVolume } from "@manysale/pro-solid-svg-icons";
import CallTransfer from "./CallTransfer";
import ProcessingOverlay from "../../../Components/Modal/ProcessingOverlay";
import WhatsappNotification from "./WhatsappNotification";

class SmHeaderDots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incoming_calls: [],
      play: false,
      agent_status: agentStatus.get(),
      incoming_request: null,
      showPopup: false,
      showOverlay: false,
      overlayMessage: "Connecting you now, please hold on...", // Call waiting message, customize as needed
      incomingCallsLength: 0,
    };

    this.url = "https://static.saleassist.ai/sounds/telephone_ring_01.wav";
    this.notifyUrl = "https://static.saleassist.ai/sounds/wachat-ding.mp3";
    this.audio = new Audio(this.url);
    this.notifyAudio = new Audio(this.notifyUrl);
    this.audio.addEventListener(
      "ended",
      function () {
        sleep(1000);
        this.play();
      },
      false
    );

    this.startPlay = this.startPlay.bind(this);
    this.startNotify = this.startNotify.bind(this);
    this.stopPlay = this.stopPlay.bind(this);

    this.timers = [];

    this.handleToggle = this.handleToggle.bind(this);
    this.setButtonAppearance = this.setButtonAppearance.bind(this);
    this.agentHasIncomingRequestsListener =
      this.agentHasIncomingRequestsListener.bind(this);
    this.messageListener = this.messageListener.bind(this);
    this.getMeetingDetails = this.getMeetingDetails.bind(this);
    this.fetch_incoming_requests = this.fetch_incoming_requests.bind(this);
    this.onSilent = this.onSilent.bind(this);
    this.removeRequestByConversationId =
      this.removeRequestByConversationId.bind(this);
    this.socket = socketContext.get()?.socket;
    this.wsmSocket = socketContext.get()?.wsmSocket;
    this.incomingRequestsListener = this.incomingRequestsListener.bind(this);

    if (this.wsmSocket) {
      this.wsmSocket.on("whatsapp_notification_message", async (data) => {
        console.log("whatsapp_notification_message from socket", data);
        const prev_incoming_requests = incomingRequests.get() || [];

        const hasUniqueField = (array, value) => {
          for (const obj of array) {
            if (obj.conversation.unique_field_value === value) {
              return false;
            }
          }
          return true;
        };

        if (
          hasUniqueField(
            prev_incoming_requests,
            data.conversation.unique_field_value
          )
        ) {
          this.setState({ showPopup: true });
          if (!snoozeRing.get()) {
            this.startNotify();
          }

          prev_incoming_requests.push(data);

          agentHasIncomingRequests.set(prev_incoming_requests.length);
          incomingRequests.set(prev_incoming_requests);
        }
      }, "event_from_smHeaderDots");

      this.wsmSocket.on("whatsapp_notification", async (data) => {
        console.log("whatsapp_notification from socket", data);
        if (data.conversation_id) {
          this.removeRequestByConversationId(data.conversation_id);
        }
      }, "event_from_smHeaderDots");

      this.wsmSocket.on("whatsapp_chat_message", async (data) => {
        console.log("whatsapp_chat_message from socket", data);
      }, "event_from_smHeaderDots");

      this.wsmSocket.on("ai_notification_message", async (data) => {
        console.log("ai_notification_message from socket", data);

        // don't show notification pop-up when bot hadover is false.
        // remove it if you want to show first message notification
        if (data.text !== "AI_HANDOVER") {
          return;
        }

        const prev_incoming_requests = incomingRequests.get() || [];

        const hasUniqueField = (array, value) => {
          for (const obj of array) {
            if (obj.conversation.unique_field_value === value) {
              return false;
            }
          }
          return true;
        };

        if (
          hasUniqueField(
            prev_incoming_requests,
            data.conversation.unique_field_value
          )
        ) {
          this.setState({ showPopup: true });
          if (!snoozeRing.get()) {
            this.startNotify();
          }

          prev_incoming_requests.push(data);

          agentHasIncomingRequests.set(prev_incoming_requests.length);
          incomingRequests.set(prev_incoming_requests);
        }
      }, "event_from_smHeaderDots");
    }
  }

  setButtonAppearance(status) {
    this.setState({ agent_status: status });
  }

  handleToggle() {
    postCall({
      url: config.url.SOURCES,
      path: "/availability/toggle_live",
      onSuccess: (response) => {
        agentStatus.set(response.data);
      },
      onFailure: (error) => {
        bottomAlert(
          error.response ? error.response.data.detail : error.toString(),
          "warning"
        );
      },
    });
  }

  messageListener(response) {
    if (response.type === "agent_status") {
      agentStatus.set(response.payload);
    } else if (
      response.type === "conversation" &&
      response.payload.message_type === "payment"
    ) {
      if (response.payload.message_content.includes("authenticated")) {
        bottomAlert(response.payload.message_content, "success");
      } else if (response.payload.message_content.includes("denied")) {
        bottomAlert(response.payload.message_content, "warning");
      } else {
        bottomAlert(response.payload.message_content, "error");
      }
    } else if (
      response.type === "conversation" &&
      response.payload.message_from != getUser().id
    ) {
      if (activeRequestContext.get()) {
        if (
          activeRequestContext.get().people_id != response.payload.message_from
        ) {
          topAlert(
            underscoreToSpacedCamel(response.payload.message_type) +
              " from " +
              response.payload.unique_field_value,
            "info"
          );
        }
      } else {
        topAlert(
          underscoreToSpacedCamel(response.payload.message_type) +
            " from " +
            response.payload.unique_field_value,
          "info"
        );
      }
    } else if (
      response.type === "incoming_request" ||
      response.type === "request_timeout"
    ) {
      this.fetch_incoming_requests();
    } else if (response.type === "lead_status") {
      topAlert(
        underscoreToSpacedCamel(response.payload.unique_field_value) +
          " " +
          response.payload.status,
        "warning"
      );
    } else if (response.type === "meeting_ended") {
      if (
        window.location.href.includes(response.payload.id) &&
        window.location.href.includes("/live/")
      ) {
        this.props.history.push(config.landing_page);
      }
    } else if (
      response.type === "session_ended" &&
      response.payload === userSession.get("session_id")
    ) {
      this.props.history.push(config.landing_page);
    }
  }

  messageErrorListener(error) {
    console.log(error);
  }

  removeRequestByConversationId(conversationId, emitOthers = false) {
    if (this.socket && emitOthers) {
      this.socket.emit("whatsapp_notification", {
        conversation_id: conversationId,
      });
    }
    const prev_incoming_requests = incomingRequests.get() || [];
    if (prev_incoming_requests.length > 0) {
      const new_incoming_requests = prev_incoming_requests.filter(
        (p) => p?.conversation?.id !== conversationId
      );
      agentHasIncomingRequests.set(new_incoming_requests.length);
      incomingRequests.set(new_incoming_requests);
    }
  }

  agentHasIncomingRequestsListener(request_count) {
    if (request_count === 0) {
      this.setState({ showPopup: false });
      this.stopPlay();
    }
  }

  stopPlay() {
    this.setState({ ...this.state });
    this.audio.pause();
  }

  startPlay() {
    this.setState({ ...this.state });
    if (!hasCookie("saleassist_auth_token")) {
      // do not play on mobile app
      this.audio
        .play()
        .then(() => {
          //
        })
        .catch(() => {
          snoozeRing.set(true);
          this.setState({ ...this.state });
        });
    }
  }

  startNotify() {
    this.setState({ ...this.state });
    if (!hasCookie("saleassist_auth_token")) {
      // do not play on mobile app
      this.notifyAudio
        .play()
        .then(() => {
          //
        })
        .catch(() => {
          snoozeRing.set(true);
          this.setState({ ...this.state });
        });
    }
  }

  fetch_incoming_requests() {
    getCall({
      url: config.url.SOURCES,
      path: "agent/get_incoming_requests/20",
      onSuccess: (response) => {
        const old_requests = incomingRequests.get() || [];
        const whatsapp_requests = old_requests.filter(
          (p) => p.message_type === "whatsapp_notification"
        );

        // Add AI chat requests in incomingRequests context to hold notification pop-up on the screen
        const aiChatRequests = old_requests.filter(
          (request) =>
            request.message_type === "ai_chat_message" ||
            request.message_type === "ai_notification"
        );

        if (
          response.data.length > 0 &&
          response.data.length != agentHasIncomingRequests.get() &&
          agentStatus.get() === "online"
        ) {
          this.setState({ showPopup: true });
          if (!snoozeRing.get()) {
            this.startPlay();
          }
        } else if (
          (response.data.length == 0 || agentStatus.get() !== "online") &&
          (whatsapp_requests.length === 0 || aiChatRequests.length === 0)
        ) {
          this.setState({ showPopup: false });
          this.stopPlay();
          snoozeRing.reset();
        }

        const latest_requests = [
          ...aiChatRequests,
          ...whatsapp_requests,
          ...response.data,
        ];
        // console.log("latest_requestsssssss", latest_requests);
        agentHasIncomingRequests.set(latest_requests.length);
        incomingRequests.set(latest_requests);
      },
      onFailure: (error) => {
        // bottomAlert("Unable to get incoming calls. " + ((error.response && error.response.data.detail) || error.toString()), "warning");
      },
    });
  }

  getAgentStatus() {
    getCall({
      url: config.url.SOURCES,
      path: "/availability/status",
      onSuccess: (response) => {
        agentStatus.set(response.data);
      },
      onFailure: (error) => {
        // bottomAlert((error.response && error.response.data.detail) || error.toString(), "warning");
      },
    });
  }

  getMeetingDetails(request_context) {
    if (request_context && request_context.meeting_id) {
      getCall({
        url: config.url.MEETINGS,
        path: request_context.meeting_id,
        onSuccess: (response) => {
          meetingContext.set(response.data);
        },
      });
    }
  }

  incomingRequestsListener(requests) {
    this.setState({ incomingCallsLength: requests.length });
  }

  componentDidMount() {
    this.timers.push(setInterval(this.fetch_incoming_requests, 2000));
    agentStatus.subscribe(this.setButtonAppearance);
    agentHasIncomingRequests.subscribe(this.agentHasIncomingRequestsListener);
    notificationsHandle.subscribe(this.messageListener);
    notificationsHandle.subscribeError(this.messageErrorListener);
    notificationsHandle.connect();
    this.getAgentStatus();
    meetingContext.subscribe(this.getAgentStatus);
    callRequestContext.subscribe(this.getAgentStatus);
    incomingRequests.subscribe(this.incomingRequestsListener);
  }

  componentWillUnmount() {
    this.stopPlay();
    agentStatus.unsubscribe(this.setButtonAppearance);
    agentHasIncomingRequests.unsubscribe(this.agentHasIncomingRequestsListener);
    notificationsHandle.unsubscribe(this.messageListener);
    notificationsHandle.unsubscribeError(this.messageErrorListener);
    notificationsHandle.close();
    this.timers.forEach((timer) => clearInterval(timer));
    meetingContext.unsubscribe(this.getAgentStatus);
    callRequestContext.unsubscribe(this.getAgentStatus);
    incomingRequests.unsubscribe(this.incomingRequestsListener);
  }

  onSilent() {
    this.stopPlay();
  }

  render() {
    var incoming_call_details = [];
    if (this.state.incoming_calls.length) {
      this.state.incoming_calls.forEach((call) => {
        incoming_call_details.push(
          <DropdownItem
            key={call.people_id}
            onClick={() => this.pickIncomingCall(call)}
          >
            {call.unique_field_value} from {call.source_name}
          </DropdownItem>
        );
      });
    } else {
      incoming_call_details.push(
        <DropdownItem>loading call details ...</DropdownItem>
      );
    }

    return (
      <Fragment>
        <Tooltip title="Set availability">
          <div
            className="header-dots"
            style={{ paddingTop: 0, marginTop: -8, paddingLeft: 10 }}
          >
            {this.state.agent_status !== "busy" ? (
              <div
                data-tut="reactour__onlineToggle"
                id="agent_status_toggle"
                // className="switch has-switch has-switch-sm mb-2 mr-3"
                className="switch has-switch has-switch-sm"
                data-on-label="ON"
                data-off-label="OFF"
                onClick={this.handleToggle}
              >
                <div
                  className={cx("switch-animate", {
                    "switch-on": this.state.agent_status !== "offline",
                    "switch-off": this.state.agent_status === "offline",
                  })}
                >
                  <span
                    className="switch-left bg-success"
                    style={{
                      contentVisibility:
                        this.state.agent_status !== "offline"
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    Online
                  </span>
                  <label>&nbsp;</label>
                  <span
                    className="switch-right bg-success"
                    style={{
                      contentVisibility:
                        this.state.agent_status === "offline"
                          ? "visible"
                          : "hidden",
                    }}
                  >
                    Offline
                  </span>
                </div>
              </div>
            ) : (
              <div
                className="switch has-switch has-switch-sm mb-2 mr-2"
                data-on-label="ON"
                data-off-label="OFF"
              >
                <div
                  className={cx("switch-animate", {
                    "switch-on": this.state.agent_status === "busy",
                    "switch-off": this.state.agent_status !== "busy",
                  })}
                >
                  <span
                    className="switch-left bg-danger"
                    style={{ cursor: "progress" }}
                  >
                    In Call
                  </span>
                  <label style={{ opacity: "0.6" }}>&nbsp;</label>
                  <span className="switch-right bg-danger"></span>
                </div>
              </div>
            )}
          </div>
        </Tooltip>
        {this.state.showPopup && (
          <Card
            className="m-2"
            style={{
              position: "fixed",
              bottom: "0px",
              right: "0px",
              width: "330px",
              maxHeight: "250px",
              overflowY: "auto",
              border: "1px solid rgba(32, 39, 140, 0.125)",
              borderRadius: "0.25rem",
              boxShadow:
                "0 1.46875rem 2.1875rem rgb(8 10 37 / 20%), 0 1.9375rem 1.40625rem rgb(8 10 37 / 20%), 0 1.25rem 1.53125rem rgb(8 10 37 / 20%), 0 1.125rem 1.1875rem rgb(8 10 37 / 20%)",
            }}
          >
            <span
              className="mr-2"
              style={{
                float: "right",
                position: "absolute",
                top: 5,
                right: 5,
                zIndex: 1,
                fontSize: "20px",
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => {
                this.stopPlay();
                this.setState({ showPopup: false });
              }}
            >
              <i>
                <FontAwesomeIcon icon={faClose} />
              </i>
            </span>
            <span
              className="mr-2"
              style={{
                float: "right",
                position: "absolute",
                top: "8px",
                right: "30px",
                zIndex: 1,
                fontSize: "16px",
                cursor: "pointer",
                color: "white",
              }}
              onClick={() => {
                if (snoozeRing.get()) {
                  this.startPlay();
                  snoozeRing.set(false);
                } else {
                  this.onSilent();
                  snoozeRing.set(true);
                }
              }}
            >
              <i>
                <FontAwesomeIcon
                  icon={snoozeRing.get() ? faVolumeXmark : faVolumeHigh}
                />
              </i>
            </span>
            <CallRequests
              title="Incoming requests"
              notime
              chatOnly={this.state.agent_status === "busy"}
              onSilent={this.stopPlay}
              removeRequestByConversationId={this.removeRequestByConversationId}
              onAccept={() => {
                this.setState({
                  showPopup: false,
                });
              }}
              handleOverlay={(isOpen) => {
                this.setState({ showOverlay: isOpen });
              }}
              popup
            />
          </Card>
        )}

        <CallTransfer />

        <WhatsappNotification
          incomingCallsLength={this.state.incomingCallsLength}
        >
          <CallRequests
            title="Whatsapp chat requests"
            history={this.props.history}
            handleOverlay={(isOpen) => {
              this.setState({ showOverlay: isOpen });
            }}
            removeRequestByConversationId={this.removeRequestByConversationId}
          />
        </WhatsappNotification>

        <ProcessingOverlay
          show={this.state.showOverlay}
          loader_type="line-scale-pulse-out"
          message={this.state.overlayMessage}
        />
      </Fragment>
    );
  }
}

export default withRouter(SmHeaderDots);
