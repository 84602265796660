import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { Button } from "reactstrap";

import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { timeFromNow, msToLongDateString } from "../../Helpers/Time";
import {
  activeRequestContext,
  agentHasIncomingRequests,
  chatItemContext,
} from "../../States/Meetings";
import { bottomAlert } from "../Notifications/ToastAlerts";
import { postCall } from "../../Helpers/Api";
import { config } from "../../config";
import { underscoreToSpacedCamel } from "../../Helpers/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { getUser } from "../../Helpers/User";
import ProcessingOverlay from "../Modal/ProcessingOverlay";

class CallRequestElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
    };
    this.attendRequest = this.attendRequest.bind(this);
    this.endRequest = this.endRequest.bind(this);
  }

  attendRequest() {
    if (this.props.incoming_request.message_type === "whatsapp_notification") {
      if (this.props.removeRequestByConversationId) {
        this.props.removeRequestByConversationId(
          this.props.incoming_request.conversation.id,
          true
        );
      }
      this.props.history.push(
        "/workspace/conversations/whatsApp/" +
          this.props.incoming_request.conversation.people_id
      );
    } else if (this.props.incoming_request.message_type === "ai_notification") {
      if (this.props.incoming_request.text === "AI_HANDOVER") {
        const conversationId = this.props.incoming_request.conversation.id;
        const sessionId = this.props.incoming_request.conversation.session_id;

        postCall({
          url: config.url.CONVERSATIONS,
          path: `/attend_conversation/${conversationId}/${sessionId}/true`,
          onSuccess: (response) => {
            // 1. Remove request
            if (this.props.removeRequestByConversationId) {
              this.props.removeRequestByConversationId(conversationId);
            }

            // 2. Redirect to leads page
            this.props.history.push(
              "/workspace/conversations/people/" +
                this.props.incoming_request.conversation.people_id
            );
          },
          onFailure: (error) => {
            console.log(error);
            bottomAlert(
              "Unable to takeover the request. " +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "warning"
            );
          },
        });
      }

      if (this.props.removeRequestByConversationId) {
        this.props.removeRequestByConversationId(
          this.props.incoming_request.conversation.id,
          true
        );
      }
    } else if (this.props.incoming_request.user_name === getUser().user_name) {
      activeRequestContext.set(this.props.incoming_request);
      this.props.history.push(
        "/workspace/conversations/people/" +
          this.props.incoming_request.people_id
      );
    } else {
      this.props.handleOverlay && this.props.handleOverlay(true); // showOverlay value

      postCall({
        url: config.url.SOURCES,
        path:
          "/agent/attend_incoming_request/" + this.props.incoming_request.id,
        onSuccess: (response) => {
          agentHasIncomingRequests.set(0);
          activeRequestContext.set(response.data);
          this.props.history.push(
            "/workspace/conversations/people/" + response.data.people_id
          );
          this.props.handleOverlay && this.props.handleOverlay(false);
        },
        onFailure: (error) => {
          bottomAlert(
            "Unable to attend the request. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
          this.props.redraw && this.props.redraw();
          this.props.handleOverlay && this.props.handleOverlay(false);
        },
      });
    }
  }

  endRequest(requestId) {
    this.setState({ showOverlay: true });
    postCall({
      url: config.url.SOURCES,
      path: "agent/end_request/" + requestId,
      onSuccess: (response) => {
        bottomAlert("Request ended.", "success");
        this.setState({ showOverlay: false });
        if (this.props.onEndRequestSuccess) {
          this.props.onEndRequestSuccess(requestId);
        }
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to end the session here." +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        this.setState({ showOverlay: false });
      },
    });
  }

  render() {
    var user_data = [];

    if (
      this.props.incoming_request?.message_type === "whatsapp_notification" ||
      this.props.incoming_request?.message_type === "ai_notification"
    ) {
      let date = timeFromNow(
        parseInt(this.props.incoming_request.user_data.date)
      ).strRepr;
      date = "since " + date.replace(" ago", "");

      return (
        <VerticalTimelineElement
          className="vertical-timeline-item"
          icon={
            <i className={"badge badge-dot badge-dot-xl badge-success"}> </i>
          }
          date={date}
        >
          <h4 className="timeline-title">
            {this.props.incoming_request.unique_field_value}
          </h4>
          <p>
            <strong>Name:</strong>{" "}
            {this.props.incoming_request?.user_data?.name}{" "}
            <strong>Type:</strong>{" "}
            {underscoreToSpacedCamel(this.props.incoming_request.message_type)}{" "}
            <strong>Number:</strong>{" "}
            {this.props.incoming_request?.user_data?.unique_field_value}
          </p>
          <Button
            style={{ flex: 1 }}
            color="success"
            onClick={(e) => {
              this.attendRequest();
            }}
          >
            Attend chat{" "}
            <i>
              <FontAwesomeIcon icon={faArrowRightToBracket} />
            </i>
          </Button>
        </VerticalTimelineElement>
      );
    } else {
      let date = timeFromNow(parseInt(this.props.call_time)).strRepr;
      date = "since " + date.replace(" ago", "");

      Object.entries(this.props.incoming_request.user_data).forEach(
        ([key, value]) => {
          user_data.push(
            <Fragment key={key}>
              <strong>{key}</strong>: {value}{" "}
            </Fragment>
          );
        }
      );

      if (this.props.notime) {
        return (
          <div className="vertical-timeline-item">
            <h4 className="timeline-title">
              {this.props.incoming_request.unique_field_value}
            </h4>
            <p>
              <strong>Name:</strong>{" "}
              {this.props.incoming_request.user_data.name}{" "}
              <strong>Type:</strong>{" "}
              {underscoreToSpacedCamel(
                this.props.incoming_request.message_type
              )}{" "}
              <strong>Widget:</strong> {this.props.incoming_request.source_name}
            </p>
            {/* <p>{ user_data }</p> */}
            {this.props.incoming_request.message_type !== "chat_request" && (
              <p>Scheduled at: {msToLongDateString(this.props.call_time)}</p>
            )}
            <p>
              Referrer:{" "}
              <a
                href={this.props.incoming_request.source_referrer}
                target="_blank"
              >
                {this.props.incoming_request.source_referrer}
              </a>
            </p>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
                maxWidth: 330,
              }}
            >
              {/* <Button
                  color="secondary"
                  style={{ flex: 1 }}
                  onClick={() => {this.props.onSilent && this.props.onSilent()}}
                  outline
                >
                  Slient{" "}
                  <i>
                    <FontAwesomeIcon icon={faVolumeXmark} />
                  </i>
                </Button> */}
              <Button
                style={{ flex: 1 }}
                color="success"
                onClick={(e) => {
                  this.attendRequest();
                  this.props.onAccept && this.props.onAccept();
                }}
              >
                {this.props.incoming_request.message_type !== "chat_request"
                  ? "Attend call"
                  : "Attend chat"}{" "}
                <i>
                  <FontAwesomeIcon icon={faArrowRightToBracket} />
                </i>
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <VerticalTimelineElement
            className="vertical-timeline-item"
            icon={
              <i className={"badge badge-dot badge-dot-xl badge-success"}> </i>
            }
            date={date}
          >
            <h4 className="timeline-title">
              {this.props.incoming_request.unique_field_value}
            </h4>
            <p>
              <strong>Name:</strong>{" "}
              {this.props.incoming_request.user_data.name}{" "}
              <strong>Type:</strong>{" "}
              {underscoreToSpacedCamel(
                this.props.incoming_request.message_type
              )}{" "}
              <strong>Widget:</strong> {this.props.incoming_request.source_name}
            </p>
            {/* <p>{ user_data }</p> */}
            {this.props.incoming_request.message_type !== "chat_request" && (
              <p>Scheduled at: {msToLongDateString(this.props.call_time)}</p>
            )}
            <p>
              Referrer:{" "}
              <a
                href={this.props.incoming_request.source_referrer}
                target="_blank"
              >
                {this.props.incoming_request.source_referrer}
              </a>
            </p>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
                maxWidth: 330,
              }}
            >
              {/* <Button
                  style={{ flex: 1 }}
                  color="secondary"
                  onClick={() => {this.props.onSilent && this.props.onSilent()}}
                  outline
                >
                  Slient{" "}
                  <i>
                    <FontAwesomeIcon icon={faVolumeXmark} />
                  </i>
                </Button> */}
              <Button
                style={{ flex: 1 }}
                color="success"
                onClick={(e) => {
                  this.attendRequest();
                  this.props.onAccept && this.props.onAccept();
                }}
              >
                {this.props.incoming_request.message_type !== "chat_request"
                  ? "Attend call "
                  : `${
                      window.location.href.includes("active-request-queue")
                        ? "Re-join "
                        : "Attend call "
                    }`}
                <i>
                  <FontAwesomeIcon icon={faArrowRightToBracket} />
                </i>
              </Button>
              {this.props.incoming_request.message_type === "chat_request" && (
                <Button
                  style={{ flex: 1 }}
                  color="danger"
                  onClick={(e) => {
                    this.endRequest(this.props.incoming_request.id);
                  }}
                >
                  End Request{" "}
                  <i>
                    <FontAwesomeIcon icon={faRightFromBracket} />
                  </i>
                </Button>
              )}
            </div>
            <ProcessingOverlay show={this.state.showOverlay} />
          </VerticalTimelineElement>
        );
      }
    }
  }
}

export default withRouter(CallRequestElement);
