import React, { useEffect, useRef, useState } from "react";
import {
  CallTransferContext,
  IncomingCallTransferContext,
} from "../../../States/CallTransfer";
import CallTransferButton from "../../../SA-VIdeo-Call/components/Buttons/call-transfer";
import InviteUserButton from "../../../SA-VIdeo-Call/components/Buttons/invite-user";
import { faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { socketContext } from "../../../States/Socket";
import { useHistory } from "react-router-dom";
import { postCall } from "../../../Helpers/Api";
import { bottomAlert } from "../../../Components/Notifications/ToastAlerts";
import { IoIosClose } from "react-icons/io";

function CallTransfer() {
  const [incomingCallTransfer, setIncomingCallTransfer] = useState(
    IncomingCallTransferContext.get()
  );
  const [callTransfer, setCallTransfer] = useState(CallTransferContext.get());
  const [isPlaying, setIsPlaying] = useState(CallTransferContext.get()?.ring);
  const [isOpen, setIsOpen] = useState(true);
  const audioRef = useRef(null);
  const history = useHistory();

  const toggleAudio = () => {
    if (audioRef.current) {
      if (!isPlaying) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      setIsPlaying(!isPlaying);
      setCallTransfer({
        ...callTransfer,
        ring: !isPlaying,
      });
    }
  };

  const handleCallAccept = () => {
    setCallTransfer({
      ...callTransfer,
      picked: true,
    });
    CallTransferContext.set({
      ...callTransfer,
      picked: true,
    });

    postCall({
      path: `meetings/${callTransfer.invite_id}/accept_invite`,
      onSuccess: (response) => {
        setIncomingCallTransfer(null);
        IncomingCallTransferContext.set(null);
        bottomAlert("Request accepted", "success");

        if (callTransfer.people_id) {
          socketContext.get().socket.emit('chat_message', { hold: false, people_id: callTransfer.people_id });
          history.push(
            "/workspace/conversations/people/" + callTransfer.people_id
          );
        } else {
          socketContext.get().socket.emit('chat_message', { hold: false, meeting_id: callTransfer.meeting_id });
          history.push("/workspace/meetings/live/" + callTransfer.meeting_id);
        }
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to complete your request. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  const handleCallDecline = () => {
    postCall({
      path: `meetings/${callTransfer.invite_id}/decline_invite`,
      onSuccess: (response) => {
        let el = incomingCallTransfer.filter(
          (obj) => obj.meeting_id !== callTransfer.meeting_id
        );

        setIncomingCallTransfer(el);
        setCallTransfer(el.length > 0 ? el[el.length - 1] : {});

        IncomingCallTransferContext.set(el);
        CallTransferContext.set(el.length > 0 ? el[el.length - 1] : {});
        bottomAlert("Request declined", "success");
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to complete your request. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  const handleIncomingCallTransfer = (index) => {
    setCallTransfer(incomingCallTransfer[index]);
  };

  useEffect(() => {
    if (socketContext.get()?.wsmSocket) {
      socketContext.get()?.wsmSocket.on("message", async (data) => {
        // console.log("Message from socket", data);

        if (data.action === "invite") {
          let callTransferData = [];

          if (IncomingCallTransferContext.get() !== null) {
            callTransferData = [...IncomingCallTransferContext.get()];
          }

          callTransferData.push({
            ...data,
            picked: false,
            ring: true,
          });

          CallTransferContext.set({
            ...data,
            picked: false,
            ring: true,
          });
          setCallTransfer({
            ...data,
            picked: false,
            ring: true,
          });
          IncomingCallTransferContext.set(callTransferData);

          setIncomingCallTransfer(callTransferData);
          setIsPlaying(true);
        }

        if (data.action === "accepted") {
          bottomAlert(`Request accepted by ${data.by}`, "success");
        }

        if (data.action === "rejected") {
          bottomAlert(`Request rejected by ${data.by}`, "error");
        }
      }, "event_from_callTransfer");
    }
  }, [socketContext.get()?.wsmSocket]);

  useEffect(() => {
    // When the component mounts, play the audio
    if (audioRef.current && isPlaying) {
      audioRef.current.play();
    }
  }, [isPlaying]);

  return callTransfer && callTransfer.meeting_id && !callTransfer.picked ? (
    <>
      <div
        className={`call-transfer-request-container m-2 ${
          isOpen ? "expanded" : "closed"
        }`}
      >
        <div className="call-routing">
          {incomingCallTransfer?.length > 1 &&
            incomingCallTransfer.map((calls, index) => (
              <span
                className={`${
                  calls.meeting_id === callTransfer.meeting_id
                    ? "call-active"
                    : ""
                }`}
                onClick={() => handleIncomingCallTransfer(index)}
              >
                {index + 1}
              </span>
            ))}
        </div>

        <div className="minimize-btn" onClick={() => setIsOpen(false)}>
          <IoIosClose />
        </div>

        <div className="header">
          {callTransfer.people_id ? "Call Transfer Request" : "Meeting Request"}
        </div>

        <span className="volume-icon" onClick={toggleAudio}>
          <i>
            <FontAwesomeIcon icon={isPlaying ? faVolumeHigh : faVolumeXmark} />
          </i>
        </span>

        <div className="icon-outer-circle">
          <div className="icon-inner-circle">
            {callTransfer.people_id ? (
              <CallTransferButton />
            ) : (
              <InviteUserButton />
            )}
          </div>
        </div>

        <div className="info">
          <span>{callTransfer.sender_email}</span>
          {callTransfer.message_type?.replace("_", " ")}
        </div>

        <div className="call-action-button">
          <button onClick={handleCallDecline}>Decline</button>
          <button onClick={handleCallAccept}>Accept</button>
        </div>

        <audio ref={audioRef} loop>
          <source
            src="https://static.saleassist.ai/sounds/telephone_ring_01.wav"
            type="audio/wav"
          />
          Your browser does not support the audio element.
        </audio>
      </div>
      <div
        className={`call-transfer-request-container-side-bar ${
          isOpen ? "closed" : "expaned"
        }`}
        onClick={() => setIsOpen(true)}
      >
        <span>
          {callTransfer.people_id ? (
            <CallTransferButton />
          ) : (
            <InviteUserButton />
          )}
        </span>
      </div>
    </>
  ) : (
    ""
  );
}

export default CallTransfer;
